/* eslint-disable react/jsx-max-depth */
import { HorizontalList } from "../../../components/common/HorizontalList";
import {
  SearchByArtistAlbumTrackForm,
  type SearchByArtistAlbumTrackFormProps,
} from "./SearchByArtistAlbumTrackForm";
import { HorizontalListLayout } from "./HorizontalListLayout";
import { layouts } from "~/components/layout";
import {} from "~/components/common/MasonryElementsShowcase";
import {
  SiteHeader,
  type SiteHeaderProps,
} from "~/components/common/SiteHeader";
import {
  SiteMenuLinks,
  type SiteMenuLinksProps,
} from "~/components/common/SiteMenuLinks";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";
import {
  ChordProgressionCard,
  type ChordProgressionCardProps,
} from "~/components/common/ChordProgressionCard";
import { Icons } from "~/components/common/_icons";
import {
  ListCard,
  type ListCardProps,
} from "~/components/common/MasonryElementsShowcase/cards/ListCard";
import {
  NamingCard,
  type NamingCardProps,
} from "~/components/common/MasonryElementsShowcase/cards/NamingCard";
import { getTrackListPagePath } from "~/routes/tracks._index/path";
import { getListPagePath } from "~/routes/lists._index/path";
import { getNamingListPagePath } from "~/routes/namings._index/path";

export type ViewProps = {
  readonly siteHeaderProps: SiteHeaderProps;
  readonly siteMenuLinksProps: SiteMenuLinksProps;
  readonly chordProgressionCardPropsList: ChordProgressionCardProps[];
  readonly listCardPropsList: ListCardProps[];
  readonly namingCardPropsList: NamingCardProps[];
  // readonly countryLinksProps: CountryLinksProps;
  readonly searchByArtistAlbumTrackFormProps: SearchByArtistAlbumTrackFormProps;
};

export function View({
  siteHeaderProps,
  siteMenuLinksProps,
  chordProgressionCardPropsList,
  listCardPropsList,
  namingCardPropsList,
  // countryLinksProps,
  searchByArtistAlbumTrackFormProps,
}: ViewProps): JSX.Element {
  const dict = useDictionary();

  return (
    <div className="pb-20">
      {/* TODO: tailwindのクラスの並び順、保存時に勝手に直したい… */}
      <layouts.SiteHeaderSection>
        <SiteHeader {...siteHeaderProps} />

        <div className="mt-2 px-4">
          <SiteMenuLinks {...siteMenuLinksProps} />
        </div>
      </layouts.SiteHeaderSection>

      <h2 className="mt-10 flex w-full justify-center text-xl">
        {dict.newest}
      </h2>

      <layouts.CenteredPageBodyWide className="max-w-full">
        <div className="flex w-full flex-col gap-4">
          <HorizontalListLayout
            title={dict.chord_progressions}
            titleIconHref={getTrackListPagePath({
              searchCondition: undefined, //TODO: なんか入れたいね…。全権を最新から検索するとか。
            })}
            icon={<Icons.Domain.ChordProgression size={20} />}
            listElem={
              <HorizontalList
                className="max-h-96 gap-4 overflow-y-clip"
                items={chordProgressionCardPropsList.map((props, index) => (
                  <div key={index} className="">
                    <ChordProgressionCard {...props} viewType="grid" />
                  </div>
                ))}
              />
            }
          />

          <HorizontalListLayout
            title={dict.naming}
            titleIconHref={getNamingListPagePath({})}
            icon={<Icons.Domain.Naming size={20} />}
            listElem={
              <HorizontalList
                className="max-h-96 gap-4 overflow-y-clip"
                items={namingCardPropsList.map((props, index) => (
                  <div key={index} className="min-w-56">
                    <NamingCard {...props} />
                  </div>
                ))}
              />
            }
          />

          <HorizontalListLayout
            title={dict.list}
            titleIconHref={getListPagePath({})}
            icon={<Icons.Domain.List size={20} />}
            listElem={
              <HorizontalList
                className="max-h-96 gap-4 overflow-y-clip"
                items={listCardPropsList.map((props, index) => (
                  <div key={index} className="min-w-56">
                    <ListCard {...props} />
                  </div>
                ))}
              />
            }
          />
        </div>
      </layouts.CenteredPageBodyWide>

      <layouts.CenteredPageBody>
        <div>
          <div className="mt-20 flex flex-col items-center text-center">
            <h2 className="flex w-full justify-center text-xl">
              {dict.search_from_x(
                `${dict.artist}${dict.comma}${dict.album}${dict.comma}${dict.track_name}`,
              )}
            </h2>
          </div>
          <div className="mt-2">
            <SearchByArtistAlbumTrackForm
              {...searchByArtistAlbumTrackFormProps}
            />
          </div>
        </div>

        {/* 
        <div>
          <div className="mt-14 flex flex-col items-center text-center">
            <h2 className="flex w-full justify-center text-xl">
              {dict.search_from_x(dict.country)}
            </h2>
            <p className="text-xs text-muted-foreground">
              {dict.with_brackets(dict.updates_may_be_delayed)}
            </p>
          </div>
          <div className="mt-2">
            <CountryLinks {...countryLinksProps} />
          </div> 
        </div>
      */}
      </layouts.CenteredPageBody>
    </div>
  );
}
